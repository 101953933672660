<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            Monitoring Tilok
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Tilok
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Kegiatan
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Item
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Foto
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Video
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.area_name }}
            </td>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center"
            >
              {{ mapActivityType(item.activity_name) }}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.detail_name }}
            </td>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center"
            >
              {{ item.total_photos }}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.total_videos }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination Component -->
    <div class="flex justify-between items-center p-4">
      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === 1"
        @click="changePage(page - 1)"
      >
        Previous
      </button>

      <span class="text-sm text-gray-700">
        Page {{ page }} of {{ totalPages }}
      </span>

      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === totalPages"
        @click="changePage(page + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "../../utils/mapActivityType";

const store = useStore();
// eslint-disable-next-line no-unused-vars
const data = computed(() => store.state.monitoring.data);
// eslint-disable-next-line no-unused-vars
const totalPages = computed(() => store.state.monitoring.totalPages);
const page = ref(1);

// eslint-disable-next-line no-unused-vars
const changePage = async (val) => {
  await store.dispatch("modal/setLoading", true);
  page.value = val;
  try {
    await store.dispatch("monitoring/getData", {
      perPage: 10,
      page: val,
    });
  } catch (error) {
    console.log(error);
  }
  await store.dispatch("modal/setLoading", false);
};

onMounted(async () => {
  await store.dispatch("modal/setLoading", true);
  try {
    await store.dispatch("monitoring/getData", {
      perPage: 10,
      page: 1,
    });
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
  await store.dispatch("modal/setLoading", false);
});
</script>
