<template>
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 lg:px-10 py-8">
            <div class="flex justify-center">
              <img :src="logo" width="150" class="mb-2" alt="" />
            </div>
            <div class="w-full flex px-4">
              <div class="w-4">
                <i
                  class="fa fa-arrow-left cursor-pointer"
                  v-if="!isEmptyRole"
                  @click="unSelectRole"
                ></i>
              </div>
              <div class="w-full text-center">
                <h6
                  class="text-lg font-bold"
                  :class="{
                    'text-blueGray-500': isEmptyRole,
                    uppercase: !isEmptyRole,
                  }"
                >
                  {{ !isEmptyRole ? selectedRole.name : "Login As" }}
                </h6>
              </div>
              <div class="w-4"></div>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-8 pt-0">
            <form @submit.prevent="login" v-if="!isEmptyRole">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="username"
                >
                  Username
                </label>
                <input
                  id="username"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Username"
                  v-model="username"
                  :readonly="[1, 2, 3].includes(selectedRole?.level)"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="password"
                >
                  Password
                </label>
                <div class="relative">
                  <input
                    id="password"
                    :type="isShowPassword ? 'text' : 'password'"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 pr-10"
                    placeholder="Password"
                    v-model="password"
                  />
                  <i
                    :class="isShowPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                    class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-blueGray-600"
                    @click="togglePassword"
                  />
                </div>
              </div>

              <div
                class="relative w-full mb-3"
                v-if="selectedRole?.level === 4"
              >
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="name"
                >
                  Nama Pengawas
                </label>
                <input
                  id="name"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Nama Pengawas"
                  v-model="name"
                />
              </div>

              <div
                class="relative w-full mb-3"
                v-if="selectedRole?.level === 4"
              >
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="phone"
                >
                  No. Handphone
                </label>
                <input
                  id="phone"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="No. Handphone"
                  v-model="phone"
                />
              </div>

              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>

              <div class="text-center mt-6">
                <button
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="submit"
                >
                  Login
                </button>
              </div>
              <div class="mt-4 text-center text-sm" style="color: #888;">
                &copy; 2024 Teman Terbaik Indonesia
              </div>
            </form>
            <div v-else class="flex flex-col items-center button-role-login">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 mt-4 w-full ease-linear transition-all duration-150"
                v-for="role in ROLES"
                :key="role.key"
                @click="selectRole(role)"
              >
                {{ role.name }}
              </button>
              <div class="mt-6 text-center text-sm" style="color: #888;">
                &copy; 2024 Teman Terbaik Indonesia
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import logo from "@/assets/img/logo.png";
// eslint-disable-next-line no-unused-vars
import { ROLES } from "../../store/constant";

const router = useRouter();
const store = useStore();

const username = ref("");
const password = ref("");
const name = ref("");
const phone = ref("");
const selectedRole = ref(null);
const isEmptyRole = ref(true);
const isShowPassword = ref(false);

// eslint-disable-next-line no-unused-vars
const selectRole = (role) => {
  selectedRole.value = role;
  isEmptyRole.value = false;
  console.log(role);

  if (role.level == 1) username.value = "PIMPINAN BKN";
  if (role.level == 2) username.value = "INSPEKTORAT";
  if (role.level == 3) username.value = "PPK";
};

// eslint-disable-next-line no-unused-vars
const unSelectRole = () => {
  selectedRole.value = null;
  isEmptyRole.value = true;
  username.value = "";
  password.value = "";
  name.value = "";
  phone.value = "";
};

// eslint-disable-next-line no-unused-vars
const togglePassword = () => (isShowPassword.value = !isShowPassword.value);

// eslint-disable-next-line no-unused-vars
const login = async () => {
  try {
    // Dispatch the login action and check if it's successful
    await store.dispatch("auth/login", {
      username: username.value,
      password: password.value,
      name: name.value,
      phone: phone.value,
      role: selectedRole.value.level,
    });

    window.location.reload();
  } catch (error) {
    console.error("Login failed:", error.message);
  }
};

onMounted(async () => {
  // Check if the login action returned a token
  if (store.state.auth.token) {
    const level = store.state.auth.level;

    // Store presence if the role is 4
    if (level == 4) {
      const formData = new FormData();
      formData.append("type", "myself");
      formData.append("name", localStorage.getItem("name"));
      formData.append("phone", localStorage.getItem("phone"));
      await store.dispatch("presence/store", formData);
    }

    // Redirect to dashboard after successful login and page refresh
    if (level == 4 || level == 5) router.push("/home/activities");
    else router.push("/home/dashboard");
  } else {
    console.error("Login failed: No token returned");
  }
});
</script>

<style scoped>
.relative .fa {
  right: 10px; /* Adjust icon position inside the input */
  top: 12px;
}
</style>
