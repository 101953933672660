<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 xl:mb-0 px-4">
        <activity-form :activity="activity"/>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
// eslint-disable-next-line no-unused-vars
import ActivityForm from "@/components/Form/ActivityForm.vue";

const store = useStore();
const route = useRoute();

// Get the id from the URL
const id = route.params.id;
// eslint-disable-next-line no-unused-vars
const activity = computed(() => store.state.activity.selectedActivity);

onMounted(async () => {
  await store.dispatch("modal/setLoading", true);
  try {
    await store.dispatch("activity/getDetailData", id);
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
  await store.dispatch("modal/setLoading", false);
});
</script>
