<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-8/12 px-4 max-w-full flex-grow">
          <span class="font-semibold text-sm text-blueGray-700">
            PROGRES KEGIATAN
          </span>
        </div>
        <div class="relative w-4/12 px-4 max-w-full flex-grow text-right">
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="seeAllPercentage"
            v-if="!isShowPercentage"
          >
            See all
          </button>
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="seeLessPercentage"
            v-if="isShowPercentage"
          >
            Back
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="w-1/2 px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Titik Lokasi
            </th>
            <th
              class="px-2 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Progress Kegiatan
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in reports" :key="report.id">
            <td
              class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center"
            >
              {{ report.location }}
            </td>
            <td
              class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex flex-col md:flex-row items-center">
                <span class="mr-2" style="min-width: 30px;">{{ report.percentage }}%</span>
                <div class="relative w-full mt-1 md:mt-0">
                  <div
                    class="overflow-hidden h-2 text-xs flex rounded bg-emerald-200"
                  >
                    <div
                      :style="`width: ${report.percentage}%`"
                      class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500"
                    ></div>
                  </div>
                  <div class="text-xs">
                    <!-- {{ report.current_state ? report.current_state.name : "" }} -->
                    <!-- ( -->
                    {{
                      report.current_state
                        ? mapActivityType(report.current_state.type)
                        : ""
                    }}
                    <!-- ) -->
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";

const store = useStore();

// eslint-disable-next-line no-unused-vars
const reports = computed(() => store.state.activity.reports);
// eslint-disable-next-line no-unused-vars
const isShowPercentage = computed(() => store.state.modal.isShowPercentage);

// eslint-disable-next-line no-unused-vars
const seeAllPercentage = async () => {
  store.dispatch("modal/setModalActivity", false);
  store.dispatch("modal/setModalPercentage", true);
  await store.dispatch("activity/getProgressReports", "*");
};

// eslint-disable-next-line no-unused-vars
const seeLessPercentage = async () => {
  store.dispatch("modal/setModalActivity", false);
  store.dispatch("modal/setModalPercentage", false);
  await store.dispatch("activity/getProgressReports", "5");
};

onMounted(async () => {
  await store.dispatch("modal/setLoading", true);
  try {
    await store.dispatch("activity/getProgressReports");
  } catch (error) {
    console.log(error);
  }
  await store.dispatch("modal/setLoading", false);
});
</script>
