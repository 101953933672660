<template>
  <div
    class="fixed min-h-screen w-full flex justify-center items-center px-4"
    style="background: rgba(0, 0, 0, 0.3); z-index: 100"
  >
    <div
      class="w-auto w-full-mobile lg:w-1/2 p-4 bg-white rounded-md shadow-md"
    >
      <div class="w-full flex justify-between mb-2">
        <div class="w-full font-semibold">Form Absensi</div>
        <div class="w-2">
          <i class="fa fa-close cursor-pointer" @click="closeModal"></i>
        </div>
      </div>
      <hr />
      <div class="w-full mt-4 p-6">
        <div class="w-full flex justify-end mb-4">
          <button
            class="px-4 py-2 bg-blueGray-700 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
            @click="addPresence"
          >
            <i class="fa fa-plus mr-2"></i> Tambah
          </button>
          <button
            class="px-4 py-2 bg-red-500 hover:shadow-lg text-white font-semibold text-sm rounded"
            @click="decreasePresence"
          >
            <i class="fa fa-minus mr-2"></i> Kurang
          </button>
        </div>

        <div class="w-full overflow-x-auto">
          <table class="w-full table-auto border-collapse">
            <thead>
              <tr class="text-left bg-blue-100 border-b border-gray-200">
                <th class="py-2 px-4 text-blueGray-700 font-semibold">Nama</th>
                <th class="py-2 px-4 text-blueGray-700 font-semibold">Role</th>
                <th class="py-2 px-4 text-blueGray-700 font-semibold">
                  No. HP
                </th>
                <th
                  class="w-20 py-2 px-4 text-center text-blueGray-700 font-semibold"
                >
                  Gambar
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="border-b border-gray-200 hover:bg-blue-50"
                v-for="(presence, index) in presences"
                :key="index"
              >
                <td class="py-3 px-4">
                  <input
                    type="text"
                    class="border border-gray-300 px-3 py-2 rounded-md text-sm w-80 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    placeholder="Nama"
                    v-model="presence.name"
                  />
                </td>
                <td>
                  <select
                    class="border border-gray-300 px-3 py-2 rounded-md text-sm w-64 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    v-model="presence.role"
                    style="width: 240px"
                  >
                    <option>Koordinator Lapangan</option>
                    <option>Teknisi</option>
                    <option>Crew</option>
                  </select>
                </td>
                <td class="py-3 px-4">
                  <input
                    type="text"
                    class="border border-gray-300 px-3 py-2 rounded-md text-sm w-80 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    placeholder="No. HP"
                    v-model="presence.phone"
                  />
                </td>
                <td class="w-20">
                  <input
                    type="file"
                    class="text-sm text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                    @change="handleFileChange($event, index)"
                    accept="image/*"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-full flex justify-end mt-2">
        <button
          class="px-4 py-2 bg-emerald-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="submitPresence"
        >
          <i class="fa fa-check mr-2"></i> Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

const $toast = useToast();
const store = useStore();

const presences = ref([
  {
    name: "",
    phone: "",
    role: "Koordinator Lapangan",
    image: null,
  },
]);

// eslint-disable-next-line no-unused-vars
const closeModal = () => {
  localStorage.setItem("askPresence", false);
  store.dispatch("modal/setModalPresence", false);
};

// eslint-disable-next-line no-unused-vars
const addPresence = () => {
  presences.value.push({
    name: "",
    phone: "",
    role: "Koordinator Lapangan",
    image: null,
  });
};

// eslint-disable-next-line no-unused-vars
const decreasePresence = () => {
  if (presences.value.length > 1) presences.value.pop();
};

// Handle file input change and set the image in presences array
// eslint-disable-next-line no-unused-vars
const handleFileChange = (event, index) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith("image/")) {
    presences.value[index].image = file; // Store the file object in presences
  } else {
    alert("Please upload a valid image file.");
  }
};

// eslint-disable-next-line no-unused-vars
const submitPresence = async () => {
  const isEmptyNamePhone = presences.value.find(
    (item) => item.name == "" || item.phone == ""
  );

  if (isEmptyNamePhone) {
    $toast.error("Terdapat nama/telepon yang belum terisi");
    return;
  }

  store.dispatch("modal/setLoading", true);

  for (let index = 0; index < presences.value.length; index++) {
    const presence = presences.value[index];
    const formData = new FormData();

    formData.append("name", presence.name);
    formData.append("phone", presence.phone);
    formData.append("role", presence.role);
    formData.append("type", "onbehalf");
    if (presence.image) {
      formData.append("photo", presence.image);
    }

    // Make a separate API call for each presence
    await store.dispatch("presence/store", formData);
  }

  await store.dispatch("presence/getData", {
    perPage: 10,
    page: 1,
  });
  store.dispatch("modal/setLoading", false);

  closeModal();
};
</script>
