import checkToken from "../../utils/checkToken";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "",
  data: [],
  totalPages: 1,
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
  SET_TOTAL_PAGE(state, totalPages) {
    state.totalPages = totalPages;
  },
};

const actions = {
  async getData({ commit }, { perPage = 10, page = 1 }) {
    try {
      const url = new URL(`${API_URL}/reports/monitoring`);

      // Use URLSearchParams to append query parameters
      const params = new URLSearchParams({ perPage, page });

      // Attach the query parameters to the URL
      url.search = params.toString();

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.token}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        commit("SET_DATA", data.data.data);
        commit("SET_TOTAL_PAGE", data.data.last_page);
      } else throw new Error("Get data failed");
    } catch (error) {
      console.error("Error get data:", error);
      checkToken(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
