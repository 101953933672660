<template>
  <div>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
      v-if="isLeader"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <i class="fa fa-arrow-left cursor-pointer" @click="goBack"></i>
            Form Gambar/Video
            <span class="font-bold">{{ selectedItem?.name }}</span>
          </div>
        </div>
      </div>
      <div class="block w-full px-4 overflow-x-auto">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
            >
              Upload Gambar/Video
            </label>
          </div>
        </div>
      </div>
      <div class="w-full px-4">
        <div
          class="w-full border flex flex-col items-center justify-center"
          style="height: 200px; text-align: center; margin: auto"
        >
          <div
            v-bind="getRootProps()"
            class="w-full h-full flex items-center justify-center border-dashed border-2 border-gray-400"
            style="cursor: pointer"
          >
            <input v-bind="getInputProps()" />
            <p v-if="isDragActive">Drop the files here ...</p>
            <p v-else>
              Drag and Drop foto/video untuk kegiatan ini <br />
              <span class="text-red-500"
                >Ukuran maksimal masing-masing file adalah 30MB</span
              >
            </p>
          </div>
          <button
            @click="open"
            class="mt-4 bg-blueGray-500 text-white px-4 py-2 rounded"
          >
            Open
          </button>
        </div>
      </div>
      <div class="w-full px-4 mt-4">
        <div v-for="(file, index) in selectedFiles" :key="index" class="mb-2">
          <div class="flex items-center">
            <span class="mr-2"
              >{{ file.name }} - {{ fileSize(file.size) }}</span
            >
            <progress :value="file.progress" max="100"></progress>
          </div>
        </div>
      </div>
      <div class="p-4">
        <button
          class="px-4 py-2 bg-emerald-500 hover:shadow-lg text-white font-semibold text-sm rounded mr-2"
          @click="submit"
        >
          <i class="fa fa-check mr-2"></i> Simpan
        </button>
      </div>
    </div>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0">
        <div class="flex flex-wrap items-center mb-4">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <i
              class="fa fa-arrow-left cursor-pointer"
              @click="goBack"
              v-if="!isLeader"
            ></i>
            Gambar/Video
            <span class="font-bold">{{ selectedItem?.name }}</span>
          </div>
        </div>
        <!-- Table Layout for Evidence -->
        <div class="w-full px-4">
          <div class="grid grid-mobile grid-rows-3 grid-cols-3">
            <div
              v-for="(evidence, index) in evidences"
              class="relative w-full h-full p-4"
              :key="index"
            >
              <div class="absolute w-full flex justify-end">
                <button
                  class="bg-red-500 text-white rounded-full px-2 py-1"
                  v-if="isLeader && activity?.status != 'accepted'"
                  @click="deleteEvidence(evidence)"
                >
                  <i class="fa fa-times"></i>
                </button>
              </div>

              <video
                v-if="evidence.type === 'video'"
                :src="evidence.file_url"
                controls
                class="w-full"
                style="height: 200px"
              >
                Your browser does not support the video tag.
              </video>
              <img
                v-else
                :src="evidence.file_url"
                alt="gambar"
                class="w-full h-full object-cover cursor-pointer"
                style="height: 200px"
                @click="showMedia(evidence)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDropzone } from "vue3-dropzone";
import { API_URL } from "@/store/constant";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

// Get the route and id from the URL
const store = useStore();
const route = useRoute();
const router = useRouter();
const activityId = route.params.id;
const id = route.params.item_id;
const $toast = useToast();

// eslint-disable-next-line no-unused-vars
const selectedItem = computed(() => store.state.activity.selectedItem);
// eslint-disable-next-line no-unused-vars
const evidences = computed(() => store.state.evidence.evidences);
// eslint-disable-next-line no-unused-vars
const isLeader = computed(() => store.state.auth.level == 5);
const isShowDeleteEvidence = computed(
  () => store.state.modal.isShowDeleteEvidence
);
// eslint-disable-next-line no-unused-vars
const activity = computed(() => store.state.activity.selectedActivity);
// List to store selected files and their upload progress
const selectedFiles = ref([]);

// Format file size in a human-readable format
// eslint-disable-next-line no-unused-vars
const fileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    " " +
    ["B", "kB", "MB", "GB", "TB"][i]
  );
};

// Function to handle file upload with progress tracking
const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    // Determine the type based on the file MIME type
    const fileType = file.type.startsWith("video/") ? "video" : "photo";

    formData.append("file", file); // Append the file to FormData
    formData.append("activity_detail_id", id); // Include any other required data
    formData.append("type", fileType); // Example for type, adjust accordingly

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${API_URL}/evidences`);
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable)
        file.progress = (event.loaded / event.total) * 100;
    };

    xhr.onload = () => {
      if (xhr.status === 201) {
        console.log("File uploaded successfully", xhr.responseText);
        resolve(xhr.responseText); // Resolve the promise when upload succeeds
      } else {
        console.error("Upload failed:", xhr.statusText);
        reject(xhr.statusText); // Reject the promise on failure
      }
    };

    xhr.onerror = () => {
      console.error("Upload error:", xhr.statusText);
      reject(xhr.statusText); // Reject the promise on error
    };

    xhr.send(formData); // Start the upload
  });
};

// eslint-disable-next-line no-unused-vars
const submit = async () => {
  const countPhotos = selectedFiles.value.filter((file) =>
    file.type.includes("image")
  ).length;
  const countVideos = selectedFiles.value.filter((file) =>
    file.type.includes("video")
  ).length;

  if (
    !(
      countPhotos + selectedItem.value.total_photo == 3 &&
      countVideos + selectedItem.value.total_video == 1
    )
  ) {
    $toast.error("Jumlah foto harus 3 dan Jumlah video harus 1");
    return;
  }
  
  try {
    await store.dispatch("modal/setLoading", true);

    // Sequentially wait for all file uploads to finish
    for (const file of selectedFiles.value) {
      await uploadFile(file); // Await each file upload before proceeding
    }

    // Only after all files are uploaded, call the following actions
    await store.dispatch("evidence/getDataByDetail", id);
    await store.dispatch("modal/setLoading", false);

    // Finally, reload the page
    location.reload();
  } catch (error) {
    console.error("Error during file submission:", error);
    await store.dispatch("modal/setLoading", false);
  }
};

// Function to handle file drop
const onDrop = (acceptFiles) => {
  for (const file of acceptFiles) {
    if (file.type.includes("image")) {
      if (selectedItem.value.total_photo >= 3) {
        $toast.error("Foto sudah melebihi jumlah maksimal");
        continue;
      } else selectedFiles.value.push(file); // Add the file to the list
    }

    if (file.type.includes("video")) {
      if (selectedItem.value.total_video >= 1) {
        $toast.error("Video sudah melebihi jumlah maksimal");
        continue;
      } else selectedFiles.value.push(file); // Add the file to the list
    }

    file.progress = 0;
  }
};

// Destructure the required properties from useDropzone
// eslint-disable-next-line no-unused-vars
const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
  onDrop,
  accept: "image/*,video/*", // Accept only images and videos
});

// eslint-disable-next-line no-unused-vars
const goBack = () => router.go(-1);

// eslint-disable-next-line no-unused-vars
const showMedia = (evidence) => {
  store.dispatch("evidence/setSelectedEvidence", evidence);
  store.dispatch("modal/setModalImage", true);
};

// eslint-disable-next-line no-unused-vars
const deleteEvidence = async (evidence) => {
  await store.dispatch("evidence/setSelectedEvidence", evidence);
  await store.dispatch("modal/setDeleteEvidence", true);
};

watch(
  () => isShowDeleteEvidence.value,
  async (value) => {
    if (!value) {
      await store.dispatch("evidence/getDataByDetail", id);
      await store.dispatch("evidence/setSelectedEvidence", null);
      await store.dispatch("modal/setLoading", false);
    }
  }
);

onMounted(async () => {
  await store.dispatch("modal/setLoading", true);
  await store.dispatch("activity/getDetailData", activityId);
  await store.dispatch("activity/getDetailItem", id);
  await store.dispatch("evidence/getDataByDetail", id);
  await store.dispatch("modal/setLoading", false);
});
</script>
