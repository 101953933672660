const state = {
  cityId: null,
  areaId: null,
  activityId: null,
  itemId: null,
  isClearFilter: true,
  startDate: '',
  endDate: ''
};

const mutations = {
  SET_CITY_ID(state, cityId) {
    state.cityId = cityId;
  },
  SET_AREA_ID(state, areaId) {
    state.areaId = areaId;
  },
  SET_ACTIVITY_ID(state, activityId) {
    state.activityId = activityId;
  },
  SET_ITEM_ID(state, itemId) {
    state.itemId = itemId;
  },
  SET_IS_CLEAR_FILTER(state, value) {
    state.isClearFilter = value;
  },
  SET_START_DATE(state, value) {
    state.startDate = value;
  },
  SET_END_DATE(state, value) {
    state.endDate = value;
  },
};

const actions = {
  setCityId({ commit }, cityId) {
    commit("SET_CITY_ID", cityId);
  },
  setAreaId({ commit }, areaId) {
    commit("SET_AREA_ID", areaId);
  },
  setActivityId({ commit }, activityId) {
    commit("SET_ACTIVITY_ID", activityId);
  },
  setItemId({ commit }, itemId) {
    commit("SET_ITEM_ID", itemId);
  },
  clearFilter({ commit }) {
    commit("SET_IS_CLEAR_FILTER", true);
  },
  dontClearFilter({ commit }) {
    commit("SET_IS_CLEAR_FILTER", false);
  },
  setStartDate({ commit }, startDate) {
    commit("SET_START_DATE", startDate);
  },
  setEndDate({ commit }, endDate) {
    commit("SET_END_DATE", endDate);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
