<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            List Absensi
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="mr-2 bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="showModal"
            v-if="isLeader"
          >
            Tambah Absensi
          </button>

          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="downloadExcel"
            v-if="isAdmin"
          >
            Download Excel
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Tanggal
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Jam
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              v-if="!isSupervisor"
            >
              Foto
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Lokasi
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Nama
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Jabatan
            </th>
            <!-- <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
              v-if="!isSupervisor"
            >
              Username
            </th> -->
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              No. Hp
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in presences" :key="item.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ dateFormat(item.created_at) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ timeFormat(item.created_at) }}
            </td>
            <td v-if="!isSupervisor">
              <img
                v-if="item.photo"
                :src="item.photo_url"
                alt="Gambar"
                style="max-width: 100px; max-height: 100"
                class="cursor-pointer"
                @click="showPresenceImage(item)"
              />
              <span v-else class="text-sm">Tidak ada foto</span>
            </td>
            <td
              class="font-semibold border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.area.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.name }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.role }}
            </td>
            <!-- <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
              v-if="!isSupervisor"
            >
              {{ item.user.username }}
            </td> -->
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.phone }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Pagination Component -->
    <div class="flex justify-between items-center p-4">
      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === 1"
        @click="changePage(page - 1)"
      >
        Previous
      </button>

      <span class="text-sm text-gray-700">
        Page {{ page }} of {{ totalPages }}
      </span>

      <button
        class="bg-indigo-500 text-white px-3 py-1 rounded"
        :disabled="page === totalPages"
        @click="changePage(page + 1)"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { dateFormat } from "../../utils/dateFormat";
// eslint-disable-next-line no-unused-vars
import { timeFormat } from "../../utils/timeFormat";

const store = useStore();
// eslint-disable-next-line no-unused-vars
const presences = computed(() => store.state.presence.presences);
const areaId = computed(() => store.state.filter.areaId);
const startDate = computed(() => store.state.filter.startDate);
const endDate = computed(() => store.state.filter.endDate);
// eslint-disable-next-line no-unused-vars
const totalPages = computed(() => store.state.presence.totalPages);
// eslint-disable-next-line no-unused-vars
const isApplyFilter = computed(() => store.state.presence.isApplyFilter);
const page = ref(1);
const isAdmin = ref(false);
const isLeader = ref(false);
const isSupervisor = ref(false);

// eslint-disable-next-line no-unused-vars
const showModal = () => store.dispatch("modal/setModalPresence", true);

// eslint-disable-next-line no-unused-vars
const showPresenceImage = (presence) => {
  store.dispatch("presence/setSelectedPresence", presence);
  store.dispatch("modal/setModalPresenceImage", true);
};

// eslint-disable-next-line no-unused-vars
const changePage = (val) => {
  page.value = val;
  store.dispatch("presence/getData", {
    perPage: 10,
    page: val,
    areaId: areaId.value,
    startDate: startDate.value,
    endDate: endDate.value,
  });
};

// eslint-disable-next-line no-unused-vars
const downloadExcel = () => {
  store.dispatch("presence/downloadExcel", areaId.value);
};

watch(
  () => isApplyFilter.value,
  async () => (page.value = 1)
);

onMounted(async () => {
  isAdmin.value = store.state.auth.level == 0;
  isSupervisor.value = store.state.auth.level == 4;
  isLeader.value = store.state.auth.level == 5;
  // try {
  //   await store.dispatch("presence/getData", { perPage: 10, page: 1 });
  // } catch (error) {
  //   console.error("Get data failed:", error.message);
  // }
});
</script>
