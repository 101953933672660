<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            <i class="fa fa-arrow-left cursor-pointer" @click="goBack"></i>
            Detail Kegiatan
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="approveActivity"
            v-if="
              (activity?.status == 'draft' ||
                activity?.status == 'progress' ||
                activity?.status == 'rejected') &&
              isSupervisor
            "
          >
            Setujui
          </button>
          <button
            class="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="rejectActivity"
            v-if="
              (activity?.status == 'draft' || activity?.status == 'progress') &&
              isSupervisor
            "
          >
            Tolak
          </button>
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            @click="editActivity"
            v-if="
              isLeader &&
              (activity?.status != 'accepted')
            "
          >
            Edit Kegiatan
          </button>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto px-4">
      <div class="w-full flex gap-6">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold"
              htmlFor="grid-password"
            >
              Jenis Kegiatan
            </label>
            <span class="text-sm">
              {{ activity ? mapActivityType(activity?.type) : "" }}
            </span>
          </div>
        </div>
        <div class="w-full lg:w-6/12 flex justify-end px-4">
          <div class="flex flex-col items-end">
            <input
              type="file"
              ref="fileInput"
              class="hidden"
              @change="handleFileUpload"
            />

            <button
              class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-if="isLeader && !activity?.bap_file"
              @click="triggerFileInput"
            >
              <i class="fa fa-upload"></i>
              Upload BA
            </button>
            <!-- Show the selected file name -->
            <div v-if="selectedFileName" class="mt-2">
              <p class="text-sm text-gray-700">
                Selected file: {{ selectedFileName }}
              </p>
            </div>
            <!-- Submit Button -->
            <button
              v-if="selectedFileName"
              class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              @click="submitFile"
            >
              Submit
            </button>
            <button
              class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-if="activity?.bap_file"
              @click="downloadBAP"
            >
              <!-- (isSupervisor || isLeader) &&  -->
              <i class="fa fa-download"></i>
              Download BA
            </button>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold"
            htmlFor="grid-password"
          >
            Titik Lokasi
          </label>
          <span class="text-sm">
            {{ activity?.area?.name }}
          </span>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold"
            htmlFor="grid-password"
          >
            Keterangan
          </label>
          <span class="text-sm">
            {{ activity?.name ?? "-" }}
          </span>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold"
            htmlFor="grid-password"
          >
            Status
          </label>
          <span class="text-sm">
            {{ activity ? mapActivityStatus(activity?.status) : "" }}
          </span>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold"
            htmlFor="grid-password"
          >
            Tanggal
          </label>
          <span class="text-sm">
            {{ activity?.date }}
          </span>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-blueGray-600 text-xs font-bold"
            htmlFor="grid-password"
          >
            Jumlah Unit / Peserta
          </label>
          <span class="text-sm">
            {{ activity?.category }}00 Unit - {{ activity?.category }}00 Peserta
          </span>
        </div>
      </div>
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative w-full mb-3">
          <label
            class="block uppercase text-red-500 text-xs font-bold"
            htmlFor="grid-password"
          >
            Catatan
          </label>
          <span class="text-sm">
            {{ activity?.notes ? activity?.notes : "-" }}
          </span>
        </div>
      </div>
      <div class="px-4 my-4">
        <table class="w-full border-collapse border border-slate-500">
          <thead>
            <tr class="bg-indigo-500 text-white">
              <th>No.</th>
              <th>Nama</th>
              <th class="px-2">Jumlah</th>
              <th class="px-2">Satuan</th>
              <th class="px-2">Foto/Video</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="activity?.details?.length < 1">
              <td colspan="5" class="text-center text-sm py-2">
                Belum ada Kategori/Barang yang dipilih
              </td>
            </tr>
            <tr v-for="(item, index) in activity?.details" :key="index" class="border">
              <td align="center" class="text-sm">{{ index + 1 }}</td>
              <td align="left" class="text-sm">
                {{ item.name }}
              </td>
              <td align="center" class="text-sm">{{ item.qty }}</td>
              <td align="center" class="text-sm">{{ item.unit }}</td>
              <td align="center">
                <button
                  class="font-semibold text-sm"
                  @click="itemGalleries(item)"
                >
                  <i class="fas fa-image text-indigo-600"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { mapActivityType } from "@/utils/mapActivityType";
// eslint-disable-next-line no-unused-vars
import { mapActivityStatus } from "@/utils/mapActivityStatus";
import { API_URL } from "../../store/constant";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

const router = useRouter();
const route = useRoute();
const store = useStore();
const $toast = useToast();

// Get the id from the URL
const id = route.params.id;
// eslint-disable-next-line no-unused-vars
const activity = computed(() => store.state.activity.selectedActivity);
// eslint-disable-next-line no-unused-vars
const isSupervisor = computed(() => store.state.auth.level == 4);
// eslint-disable-next-line no-unused-vars
const isLeader = computed(() => store.state.auth.level == 5);
// eslint-disable-next-line no-unused-vars
const fileInput = ref(null);
const file = ref(null);
const selectedFileName = ref(null); // Store selected file name

// eslint-disable-next-line no-unused-vars
const editActivity = () => router.push(`/home/activities/${id}/edit`);

// eslint-disable-next-line no-unused-vars
const approveActivity = () => store.dispatch("modal/setModalApproval", true);
// eslint-disable-next-line no-unused-vars
const rejectActivity = () => store.dispatch("modal/setModalReject", true);

// eslint-disable-next-line no-unused-vars
const itemGalleries = (activityDetail) =>
  router.push(`/home/activities/${id}/galleries/${activityDetail.id}`);

// eslint-disable-next-line no-unused-vars
const triggerFileInput = () => fileInput.value.click();

// eslint-disable-next-line no-unused-vars
const goBack = () => router.go(-1);

// Handle file upload when a file is selected
// eslint-disable-next-line no-unused-vars
const handleFileUpload = (event) => {
  file.value = event.target.files[0];
  if (file.value) {
    selectedFileName.value = file.value.name; // Store the file name
    console.log("Selected file:", file.value);
    // Add any additional logic here for handling the file (e.g., validate file type, size, etc.)
  }
};

// Submit the selected file
// eslint-disable-next-line no-unused-vars
const submitFile = async () => {
  await store.dispatch("modal/setLoading", true);
  if (selectedFileName.value) {
    const formData = new FormData();
    formData.append("file", fileInput.value.files[0]); // Get the file from the input

    try {
      await store.dispatch("modal/setLoading", true);
      const response = await fetch(`${API_URL}/activities/${id}/uploadBAP`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${store.state.auth.token}`, // Include the authorization token if needed
        },
        body: formData, // Attach the form data
      });

      if (!response.ok) {
        throw new Error("File upload failed");
      }

      const result = await response.json();
      console.log("File uploaded successfully:", result);

      await store.dispatch("modal/setLoading", false);
      await store.dispatch("activity/getDetailData", id);
      $toast.success("Berhasil upload BA");
      selectedFileName.value = null;
    } catch (error) {
      await store.dispatch("modal/setLoading", false);
      console.error("Error uploading file:", error);
      alert("There was an error uploading the file.");
      $toast.error("Terjadi kesalahan saat upload, mohon refresh halaman");
    }
    await store.dispatch("modal/setLoading", false);
  }
};

// eslint-disable-next-line no-unused-vars
const downloadBAP = async () => {
  await store.dispatch("modal/setLoading", true);
  await store.dispatch("activity/downloadBAP", {
    id,
    filename: activity.value.bap_file,
    type: mapActivityType(activity.value.type),
  });
  await store.dispatch("modal/setLoading", false);
};

onMounted(async () => {
  try {
    await store.dispatch("modal/setLoading", true);
    await store.dispatch("activity/getDetailData", id);
    await store.dispatch("modal/setLoading", false);
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
});
</script>
