import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import store from "./store";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// layouts

import Admin from "@/layouts/Admin.vue";
import Auth from "@/layouts/Auth.vue";

// views for Admin layout

import Dashboard from "@/views/admin/Dashboard.vue";
import Activities from "@/views/admin/Activities/Index.vue";
import AddActivity from "@/views/admin/Activities/Add.vue";
import EditActivity from "@/views/admin/Activities/Edit.vue";
import ActivityDetail from "@/views/admin/Activities/Detail.vue";
import ActivityGallery from "@/views/admin/Activities/Gallery.vue";
import Locations from "@/views/admin/Locations.vue";
import Monitoring from "@/views/admin/Monitoring.vue";
import Users from "@/views/admin/Users.vue";
import Presences from "@/views/admin/Presences.vue";
import Handovers from "./views/admin/Handovers.vue";
import Galleries from "./views/admin/Galleries.vue";

// views for Auth layout
import Login from "@/views/auth/Login.vue";
import LoginAdmin from "./views/auth/LoginAdmin.vue";

// routes
const routes = [
  {
    path: "/home",
    redirect: "/home/dashboard",
    component: Admin,
    children: [
      {
        path: "/home/dashboard",
        component: Dashboard,
        name: "Dashboard",
      },
      {
        path: "/home/activities",
        component: Activities,
        name: "Kegiatan",
      },
      {
        path: "/home/activities/add",
        component: AddActivity,
        name: "Tambah Kegiatan",
      },
      {
        path: "/home/activities/:id",
        component: ActivityDetail,
        name: "Detail Kegiatan",
      },
      {
        path: "/home/activities/:id/detail",
        component: ActivityDetail, // This could be the same or another component based on your design
        name: "Detail Kegiatan",
      },
      {
        path: "/home/activities/:id/edit",
        component: EditActivity,
        name: "Edit Kegiatan",
      },
      {
        path: "/home/activities/:id/galleries/:item_id",
        component: ActivityGallery,
        name: "Upload Media",
      },
      {
        path: "/home/locations",
        component: Locations,
        name: "Lokasi",
      },
      {
        path: "/home/monitoring",
        component: Monitoring,
        name: "Monitoring",
      },
      {
        path: "/home/users",
        component: Users,
        name: "Pengguna",
      },
      {
        path: "/home/presences",
        component: Presences,
        name: "Absensi",
      },
      {
        path: "/home/handovers",
        component: Handovers,
        name: "BAP",
      },
      {
        path: "/home/galleries",
        component: Galleries,
        name: "Media",
      },
      {
        path: "/home/activity_reports",
        component: Activities,
        name: "Laporan Kegiatan",
      },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    component: Auth,
    children: [
      {
        path: "/",
        component: Login,
      },
      {
        path: "/login-admin",
        component: LoginAdmin,
      },
    ],
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token) {
      // If token exists, check its validity with a backend or proceed
      // Example: You can call an API to validate the token here
      try {
        // Validate the token with an API request or similar
        // If validation fails, redirect to login
        next(); // Token is valid, proceed
      } catch (error) {
        next("/"); // Token is invalid, redirect to login
      }
    } else {
      next("/"); // No token, redirect to login
    }
  } else {
    next(); // Route does not require authentication
  }
});

createApp(App).use(router).use(store).mount("#app");
