// src/store/index.js

import { createStore } from "vuex";
import auth from "./modules/auth";
import city from "./modules/city";
import area from "./modules/area";
import item from "./modules/item";
import user from "./modules/user";
import presence from "./modules/presence";
import activity from "./modules/activity";
import evidence from "./modules/evidence";
import monitoring from "./modules/monitoring";
import modal from "./modules/modal";
import filter from "./modules/filter";

export default createStore({
  modules: {
    auth,
    city,
    area,
    item,
    user,
    presence,
    activity,
    evidence,
    monitoring,
    modal,
    filter,
  },
});
