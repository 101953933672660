import { useToast } from "vue-toast-notification";
import { API_URL } from "../constant";

const state = {
  token: localStorage.getItem("token") || "", // Get token from localStorage on page load
  isAuthenticated: !!localStorage.getItem("token"), // Set isAuthenticated based on token
  name: localStorage.getItem("name") || "",
  phone: localStorage.getItem("phone") || "",
  level:
    localStorage.getItem("level") == "0"
      ? 0
      : parseInt(localStorage.getItem("level")) || 5,
};

const $toast = useToast();
const mutations = {
  SET_TOKEN(state, data) {
    state.token = data.token;
    state.name = data.name;
    state.phone = data.phone;
    state.level = data.level;
    state.isAuthenticated = true;

    localStorage.setItem("token", data.token); // Save token to localStorage
    localStorage.setItem("name", data.name); // Save name to localStorage
    localStorage.setItem("phone", data.phone); // Save phone to localStorage
    localStorage.setItem("level", data.level.toString()); // Save level to localStorage
  },
  CLEAR_TOKEN(state) {
    state.token = "";
    state.name = "";
    state.phone = "";
    state.level = 5;
    state.isAuthenticated = false;
    localStorage.removeItem("token"); // Remove token from localStorage
    localStorage.removeItem("name"); // Remove name from localStorage
    localStorage.removeItem("phone"); // Remove phone from localStorage
    localStorage.removeItem("level"); // Remove level from localStorage
    localStorage.removeItem("askPresence");
  },
};

const actions = {
  async login({ commit }, credentials) {
    try {
      if (
        parseInt(credentials.role) == 4 &&
        (credentials.name == "" || credentials.phone == "")
      ) {
        throw new Error(1);
      }
      // Perform the login API request
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: credentials.username,
          password: credentials.password,
          level: parseInt(credentials.role),
        }),
      });

      localStorage.setItem("askPresence", false);

      const data = await response.json();

      if (response.ok && data.token) {
        // Save token in Vuex and localStorage
        commit("SET_TOKEN", {
          token: data.token,
          name: parseInt(credentials.role) === 4 ? credentials.name : data.name,
          phone:
            parseInt(credentials.role) === 4 ? credentials.phone : data.phone,
          level: parseInt(credentials.role),
        });

        localStorage.setItem("askPresence", data.askPresence);
      } else {
        // $toast.error("Kombinasi username dan password salah!");
        throw new Error("Login failed");
      }
    } catch (error) {
      if (error.message == 1) {
        $toast.error("Nama dan Telepon wajib diisi!");
      } else {
        $toast.error("Kombinasi username dan password salah!");
      }
      throw error;
    }
  },

  async logout({ commit }) {
    try {
      // Perform the logout API request
      await fetch(`${API_URL}/logout`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      });

      // Clear token in Vuex and localStorage
      commit("CLEAR_TOKEN");
    } catch (error) {
      console.error("Error during logout:", error);
      commit("CLEAR_TOKEN");
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
