<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700 uppercase">
            List Lokasi
          </h3>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Kota
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Lokasi
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Alamat
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Pengawas
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Korlap
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            >
              Detail
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in areas" :key="item.id">
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ item.city.name }}
            </td>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center"
            >
              {{ item.name }}
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4"
            >
              {{ truncateText(item.address, 45) }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 capitalize"
            >
              <div class="flex justify-center">
                <i
                  class="fas fa-circle text-emerald-500 mr-2"
                  v-if="item.is_supervisor_presence"
                ></i>
                <i class="fas fa-circle text-red-500 mr-2" v-else></i>
              </div>
              <!-- {{ item.supervisor.name }} -->
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 capitalize flex justify-center"
            >
              <i
                class="fas fa-circle text-emerald-500 mr-2"
                v-if="item.is_leader_presence"
              ></i>
              <i class="fas fa-circle text-red-500 mr-2" v-else></i>
              <!-- {{ item.leader.name }} -->
            </td>
            <td class="px-4 text-center">
              <button class="font-semibold text-sm" @click="showActivity(item)">
                <i class="fas fa-eye"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
// eslint-disable-next-line no-unused-vars
import { truncateText } from "@/utils/truncateText";

const router = useRouter();
const store = useStore();
// eslint-disable-next-line no-unused-vars
const areas = computed(() => store.state.area.areas);

// eslint-disable-next-line no-unused-vars
const showActivity = async (area) => {
  await store.dispatch("filter/dontClearFilter");
  await store.dispatch("filter/setCityId", area.city_id);
  await store.dispatch("filter/setAreaId", area.id);
  await store.dispatch("activity/getData", {
    perPage: 10,
    page: 1,
    areaId: area.id,
  });
  router.push("/home/activities");
};

onMounted(async () => {
  await store.dispatch("modal/setLoading", true);
  try {
    await store.dispatch("area/getData");
  } catch (error) {
    console.error("Get data failed:", error.message);
  }
  await store.dispatch("modal/setLoading", false);
});
</script>
