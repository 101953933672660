<template>
  <div class="flex mt-4">
    <div class="w-full px-4">
      <card-monitoring />
    </div>
  </div>
</template>
<script>
import CardMonitoring from "@/components/Dashboard/CardMonitoring.vue";

export default {
  components: {
    CardMonitoring
  },
};
</script>
